import React, { useState, useEffect } from 'react';
import {observer} from "mobx-react-lite";
import { Container } from 'react-bootstrap';
import BubbleLab from '../img/bubble-lab.png';
import { Link } from 'react-router-dom';
import API_URL from '../http';

const Footer = () => {
  const [items, setItems] = useState([]);

  const getSettings = async () => {
    const res = await fetch(
      API_URL + `/s`);
    const data = await res.json();
    setItems(data);
  };

  useEffect(() => {
    getSettings();
  }, [setItems]);

    return (
      <div className='footer'>
        <Container className='py-4'>
          <footer className="d-flex flex-wrap justify-content-between align-items-center my-5">
              <p className="col-md-4 mb-0 text-white-50 vr-nav_text col-xs-12">© 2023 {items.orgName}<br />ИНН: {items.innOrg} ОГРНИП: {items.ogrnOrg}</p>
              <ul className="nav col-md-3 justify-content-end">
                <li className="nav-item"><Link to="/" className="nav-link px-1 text-white-50 vr-nav_text">Главная</Link></li>
                <li className="nav-item"><Link to="/games" className="nav-link px-1 text-white-50 vr-nav_text">Игры</Link></li>
                <li className="nav-item"><Link to="/news" className="nav-link px-1 text-white-50 vr-nav_text">Новости</Link></li>
                <li className="nav-item"><Link to="/contacts" className="nav-link px-1 text-white-50 vr-nav_text">Контакты</Link></li>
              </ul>
              <div className="d-flex col-md-4 justify-content-end">
                <img className='bubble-lab' alt="Bubble Lab" src={BubbleLab} />
                <a href="https://bubble-lab.ru/" rel="noreferrer" target='_blank' className="nav-link mb-0 text-white-50 vr-nav_text">Разработка сайтов<br/>Bubble Lab</a>
              </div>
            </footer>
          </Container>
        </div>
    );
};

export default observer(Footer);