import React, {useState, useEffect} from 'react';
import { observer } from "mobx-react-lite";
import Header from './maket/header';
import Footer from './maket/footer';
import { Outlet } from 'react-router-dom';
import IMG from '../components/img/bg-2.png';

const MaketAlt = () => {

  return (
    <>
      <div className="vr-bg bg_fixed" style={{background: `#393E41 url(${IMG}) no-repeat top`, marginTop: '0'}}>
        <Header />
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default observer(MaketAlt);