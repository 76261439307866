import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import {Link} from 'react-router-dom';
import API_URL, {IMG_URL} from '../components/http';

const NewsPage = () => {

  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  let limit = 6;
  document.title = 'Новости | VR Surgut - Виртуальная реальность сейчас!'
  const [settings, setSettings] = useState([]);

  const getSettings = async () => {
    const res = await fetch(
      API_URL + `/s`);
    const data = await res.json();
    setSettings(data);
  };

  useEffect(() => {
    getSettings();
  }, [setSettings]);


  const getNews = async () => {
    const res = await fetch(
      API_URL + `/n?page=1&limit=${limit}`);
    const data = await res.json();
    const total = res.headers.get("x-total-count");
    setpageCount(Math.ceil(total / limit));
    setItems(data);
  };

  const fetchNews = async (currentPage) => {
    const res = await fetch(
      API_URL + `/n?page=${currentPage}&limit=${limit}`);
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    const commentsFormServer = await fetchNews(currentPage);
    setItems(commentsFormServer);
  };

  useEffect(() => {
    getNews();
  }, [limit]);

    return (
      <section className="vr-main" style={{height: 'auto', marginTop: 0}}>
          <Container>
            <Row md={12} className='d-flex justify-content-center'>
              <Col md={12} className='d-flex justify-content-center'>
                <h4 className='vr-games_title'>Список всех <span>новостей</span></h4>
              </Col>
              <Col md={12} className='d-flex justify-content-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="170" height="15" viewBox="0 0 170 15" fill="none">
                  <path d="M1 7.5C6.6 -1.16667 12.2 -1.16667 17.8 7.5C23.4 16.1667 29 16.1667 34.6 7.5C40.2 -1.16667 45.8 -1.16667 51.4 7.5C57 16.1667 62.6 16.1667 68.2 7.5C73.8 -1.16667 79.4 -1.16667 85 7.5C90.6 16.1667 96.2 16.1667 101.8 7.5C107.4 -1.16667 113 -1.16667 118.6 7.5C124.2 16.1667 129.8 16.1667 135.4 7.5C141 -1.16667 146.6 -1.16667 152.2 7.5C157.8 16.1667 163.4 16.1667 169 7.5" stroke="#FFBF46" strokeOpacity="0.7" strokeWidth="2"/>
                </svg>
              </Col>
              <Col md={4} className='d-flex justify-content-center'>
                <p md={12} className='vr-games_text'>{settings.headerAllNews}</p>
              </Col>
            </Row>
            <Row md={12} className='d-flex justify-content-center gap-5 vr-games_cards'>
            {items.map((item) => {
              return (
              <div key={item.news_id} className='vr-games_card'>
                <img className='vr-games_card_img' alt={item.title} src={IMG_URL + '/' + item.img} />
                <div className='d-flex justify-content-between vr-games_card_info'>
                  <h5 className='vr-games_card_title'>{item.title}</h5>
                </div>
                <p className='vr-games_card_shortdesc'>{item.fullNews.substring(0, 100)} ...</p>
                <div className='vr-header_btn_group d-flex justify-content-between'>
                  <Link to={`/news/` + item.news_id}><button type="button" className="btn btn-warning vr-btn">Читать новость</button></Link>
                  <button style={{cursor: 'default'}} type="button" className="btn btn-dark vr-btn_alt_2">{item.pubDate.substring(0, 10)}</button>
                </div>
              </div>
              )
            })}
            </Row>
            <Row md={12} className='d-flex justify-content-center vr-games-cards_footer mt-4'>
              <Col md={4} className='d-flex justify-content-center'>
                <ReactPaginate
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  previousLabel={"Назад"}
                  nextLabel={"Вперед"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  breakClassName="page-item"
                  breakLabel={<div className="page-link">...</div>}
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  activeClassName="active paginate-btn-active"
                />
              </Col>
            </Row>
          </Container>
      </section>
    );
};

export default NewsPage;