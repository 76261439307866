import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import { Col, Container, Row } from 'react-bootstrap';
import SocialImg from '../components/img/social.png';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import API_URL, {IMG_URL} from '../components/http';


const HomePage = () => {

  const [index, setIndex] = useState(0);
  const [items, setItems] = useState([]);
  const [games, setGames] = useState([]);
  const [slides, setSlides] = useState([]);
  const [bigGames, setBigGames] = useState([]);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  document.title = 'VR Surgut - Виртуальная реальность сейчас!'

  const getSettings = async () => {
    const res = await fetch(
      API_URL + `/s`);
    const data = await res.json();
    setItems(data);
  };

  const getSlides = async () => {
    const res = await fetch(
      API_URL + `/c`);
    const data = await res.json();
    setSlides(data);
  };

  const getGames = async () => {
    let limit = 4;
    const res = await fetch(
      API_URL + `/g?page=1&limit=${limit}`);
    const data = await res.json();
    setGames(data);
  };

  const getBigGames = async () => {
    let limit = 3;
    const res = await fetch(
      API_URL + `/g?page=1&limit=${limit}`);
    const data = await res.json();
    setBigGames(data);
  };

  useEffect(() => {
    getSlides();
  }, [setSlides]);

  useEffect(() => {
    getBigGames();
  }, [setBigGames]);

  useEffect(() => {
    getGames();
  }, [setGames]);

  useEffect(() => {
    getSettings();
  }, [setItems]);

    return (
      <>
        <section className="vr-main">
          <Container>
            <Row md={12} className='d-flex justify-content-center'>
              <Col className='vr-header_main' md={8}>
                <h4 className='vr-header_title_main mt-4'>
                  {items.headerText} <span>{items.headerAltText}</span>
                </h4>
                <p className='vr-header_text_main'>
                  {items.headerFullText}
                </p>
              </Col>
            </Row>
            <Row md={12} className='d-flex justify-content-center vr-arrow-box'>
              <Col md={12} className='d-flex justify-content-center'>
                <i className='bx bxs-chevrons-down vr-header_bottom_arrow'></i>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='vr-games'>
          <Container>
            <Row md={12} className='d-flex justify-content-center'>
              <Col md={12} className='d-flex justify-content-center'>
                <h4 className='vr-games_title'>Список доступных <span>игр</span></h4>
              </Col>
              <Col md={12} className='d-flex justify-content-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="170" height="15" viewBox="0 0 170 15" fill="none">
                  <path d="M1 7.5C6.6 -1.16667 12.2 -1.16667 17.8 7.5C23.4 16.1667 29 16.1667 34.6 7.5C40.2 -1.16667 45.8 -1.16667 51.4 7.5C57 16.1667 62.6 16.1667 68.2 7.5C73.8 -1.16667 79.4 -1.16667 85 7.5C90.6 16.1667 96.2 16.1667 101.8 7.5C107.4 -1.16667 113 -1.16667 118.6 7.5C124.2 16.1667 129.8 16.1667 135.4 7.5C141 -1.16667 146.6 -1.16667 152.2 7.5C157.8 16.1667 163.4 16.1667 169 7.5" stroke="#FFBF46" strokeOpacity="0.7" strokeWidth="2"/>
                </svg>
              </Col>
              <Col md={4} className='d-flex justify-content-center'>
                <p md={12} className='vr-games_text'>{items.headerAllGames}</p>
              </Col>
            </Row>
            <Row md={12} className='d-flex justify-content-center gap-5 vr-games_cards'>
            {bigGames.map((bigGame) => {
                return (
                <div key={bigGame.games_id} className='vr-games_card'>
                  <img className='vr-games_card_img' alt={bigGame.title} src={IMG_URL + '/' + bigGame.img} />
                  <div className='d-flex justify-content-between vr-games_card_info'>
                    <h5 className='vr-games_card_title'>"{bigGame.title}"</h5>
                    <p className='vr-games_card_cost'><strong>{bigGame.costSmall}</strong> </p>
                  </div>
                  <p className='vr-games_card_shortdesc'>{bigGame.description.substring(0, 100)} ...</p>
                  <div className='vr-header_btn_group d-flex justify-content-between'>
                    <a href="https://n942992.yclients.com/" rel="noreferrer" target="_blank"><button type="button" className="btn btn-warning vr-btn">Записаться онлайн</button></a>
                    <Link to={'/games/' + bigGame.games_id}><button type="button" className="btn btn-dark vr-btn_alt_2">Подробнее</button></Link>
                  </div>
                </div>
                )
              })}
            </Row>
            <Row md={12} className='d-flex justify-content-center vr-games-cards_footer'>
              <Col md={4} className='d-flex justify-content-center'>
                <Link style={{textDecoration: 'none'}} to="/games"><p md={12} className='vr-games_text'>Посмотреть все игры...</p></Link>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="vr-room">
          <Container>
          <Row md={12} className='d-flex justify-content-center'>
              <Col md={12} className='d-flex justify-content-center'>
                <h4 className='vr-games_title'>Немного <span>о нас</span></h4>
              </Col>
              <Col md={12} className='d-flex justify-content-center mb-4'>
                <svg xmlns="http://www.w3.org/2000/svg" width="170" height="15" viewBox="0 0 170 15" fill="none">
                  <path d="M1 7.5C6.6 -1.16667 12.2 -1.16667 17.8 7.5C23.4 16.1667 29 16.1667 34.6 7.5C40.2 -1.16667 45.8 -1.16667 51.4 7.5C57 16.1667 62.6 16.1667 68.2 7.5C73.8 -1.16667 79.4 -1.16667 85 7.5C90.6 16.1667 96.2 16.1667 101.8 7.5C107.4 -1.16667 113 -1.16667 118.6 7.5C124.2 16.1667 129.8 16.1667 135.4 7.5C141 -1.16667 146.6 -1.16667 152.2 7.5C157.8 16.1667 163.4 16.1667 169 7.5" stroke="#FFBF46" strokeOpacity="0.7" strokeWidth="2"/>
                </svg>
              </Col>
            </Row>
            <Carousel activeIndex={index} onSelect={handleSelect}>
              {slides.map((slide) => {
                return (
                <Carousel.Item key={slide._id}>
                  <img
                    className="d-block w-100"
                    src={IMG_URL + slide.img}
                    alt="Third slide"
                    style={{height: '500px', objectFit: 'cover', borderRadius: '35px'}}
                  />
                  <Carousel.Caption>
                    <h3 className="vr-games_title"><span className="text-shadow-lg" style={{textShadow: '1px 0 1px rgb(57, 62, 65)'}}>{slide.title}</span></h3>
                    <p style={{textShadow: '1px 0 1px rgb(57, 62, 65)'}} className="vr-games_card_title text-white">
                      {slide.description}
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                )
              })}
            </Carousel>
          </Container>
        </section>









        <section>
        <Container className="vr-howtogame">
            <Row md={12} className='d-flex justify-content-center'>
              <Col md={12} className='d-flex justify-content-center'>
                <h4 className='vr-games_title'>А как <span>сыграть?</span></h4>
              </Col>
              <Col md={12} className='d-flex justify-content-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="170" height="15" viewBox="0 0 170 15" fill="none">
                  <path d="M1 7.5C6.6 -1.16667 12.2 -1.16667 17.8 7.5C23.4 16.1667 29 16.1667 34.6 7.5C40.2 -1.16667 45.8 -1.16667 51.4 7.5C57 16.1667 62.6 16.1667 68.2 7.5C73.8 -1.16667 79.4 -1.16667 85 7.5C90.6 16.1667 96.2 16.1667 101.8 7.5C107.4 -1.16667 113 -1.16667 118.6 7.5C124.2 16.1667 129.8 16.1667 135.4 7.5C141 -1.16667 146.6 -1.16667 152.2 7.5C157.8 16.1667 163.4 16.1667 169 7.5" stroke="#FFBF46" strokeOpacity="0.7" strokeWidth="2"/>
                </svg>
              </Col>
              <Row md={12} className='d-flex justify-content-center gap-2 vr-games_cards'>
                <Col>
                  <div className='vr-howtogame_item'>
                    <i className='bx bx-game vr-howtogame_item_icon' ></i>
                  </div>
                  <h3 className="vr-howtogame_item_header">{items.howToGameText1}</h3>
                </Col>

                <Col className="vr-howtogame_item_alt-box">
                  <div className="vr-howtogame_item_alt">
                    <i className='bx vr-howtogame_item_icon_alt bx-dots-horizontal bx-flashing' ></i>
                  </div>
                </Col>

                <Col>
                  <div className='vr-howtogame_item'>
                    <i className='bx bx-mouse-alt vr-howtogame_item_icon' ></i>
                  </div>
                  <h3 className="vr-howtogame_item_header">{items.howToGameText2}</h3>
                </Col>

                <Col className="vr-howtogame_item_alt-box">
                  <div className="vr-howtogame_item_alt">
                    <i className='bx vr-howtogame_item_icon_alt bx-dots-horizontal bx-flashing' ></i>
                  </div>
                </Col>

                <Col>
                  <div className='vr-howtogame_item'>
                    <i className='bx bxs-edit vr-howtogame_item_icon' ></i>
                  </div>
                  <h3 className="vr-howtogame_item_header">{items.howToGameText3}</h3>
                </Col>

                <Col className="vr-howtogame_item_alt-box">
                  <div className="vr-howtogame_item_alt">
                    <i className='bx vr-howtogame_item_icon_alt bx-dots-horizontal bx-flashing' ></i>
                  </div>
                </Col>

                <Col>
                  <div className='vr-howtogame_item'>
                    <i className='bx bx-time-five vr-howtogame_item_icon' ></i>
                  </div>
                  <h3 className="vr-howtogame_item_header">{items.howToGameText4}</h3>
                </Col>
              </Row>
            </Row>
          </Container>
        </section>










        <section>
          <Container>
            <Row md={12} className='d-flex justify-content-between'>
              <Col className="vr-social" style={{background: `#393E41 url(${SocialImg}) no-repeat top`}} md={12}>
                <Row className='d-flex justify-content-between vr-social_content mx-4 ms-1'>
                  <Col md={4}>
                    <h4 className='vr-header_title'>
                      VR Сургут <span>ВКонтакте</span>
                    </h4>
                    <h5 className='vr-games_card_title'>{items.headerVkDecLink}</h5>
                    <p className='vr-header_text'>
                      {items.headerVkText}
                    </p>
                    <a href={items.headerVkLink} rel="noreferrer" target="_blank"><button type="button" className="btn btn-warning vr-btn">Подписаться на сообщество</button></a>
                  </Col>
                  <Col md={3}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="317" height="188" viewBox="0 0 317 188" fill="none">
                      <path d="M308.869 12.9662C311.074 5.64248 308.869 0.271729 298.443 0.271729H263.903C255.115 0.271729 251.098 4.91799 248.893 10.021C248.893 10.021 231.332 52.8452 206.447 80.6125C198.415 88.6922 194.745 91.2437 190.366 91.2437C188.177 91.2437 184.995 88.6922 184.995 81.3685V12.9662C184.995 4.17773 182.46 0.271729 175.136 0.271729H120.862C115.381 0.271729 112.073 4.33523 112.073 8.20973C112.073 16.5257 124.515 18.4472 125.791 41.8832V92.7242C125.791 103.859 123.791 105.891 119.381 105.891C107.679 105.891 79.2027 62.9095 62.303 13.7065C59.0112 4.14623 55.688 0.287485 46.868 0.287485H12.344C2.46875 0.287485 0.5 4.93373 0.5 10.0367C0.5 19.2032 12.2023 64.5632 55.0107 124.555C83.5498 165.521 123.728 187.728 160.331 187.728C182.271 187.728 184.98 182.798 184.98 174.293V143.329C184.98 133.469 187.074 131.485 194.02 131.485C199.123 131.485 207.912 134.068 228.402 153.803C251.807 177.207 255.682 187.712 268.833 187.712H303.357C313.216 187.712 318.146 182.783 315.311 173.049C312.208 163.363 301.026 149.267 286.189 132.587C278.125 123.074 266.076 112.837 262.407 107.718C257.288 101.119 258.768 98.2052 262.407 92.3462C262.422 92.362 304.491 33.1105 308.869 12.9662Z" fill="white" fillOpacity="0.25"/>
                    </svg>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
};

export default observer(HomePage);