import React, {useState, useEffect} from 'react';
import { observer } from "mobx-react-lite";
import Header from './maket/header';
import Footer from './maket/footer';
import { Outlet } from 'react-router-dom';
import API_URL, {IMG_URL} from './http';
const Maket = () => {
  const [img, setImg] = useState('');

  const getBigGames = async () => {
    const res = await fetch(
      API_URL + `/c/b`);
    const data = await res.json();
    const random = Math.floor(Math.random() * data.length);
    const finishImg = data[random].img;
    setImg(IMG_URL + finishImg);
  };

  useEffect(() => {
    getBigGames();
  }, [setImg]);

  return (
    <>
      <div className="vr-bg" style={{ background: `#393E41 url(${img}) no-repeat top`}}>
        <Header />
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default observer(Maket);