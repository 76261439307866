import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import { Col, Container, Row } from 'react-bootstrap';
import SocialImg from '../components/img/social.png';
import { Link } from 'react-router-dom';
import API_URL from '../components/http';


const ContactsPage = () => {
  const [settings, setSettings] = useState([]);

  const getSettings = async () => {
    const res = await fetch(
      API_URL + `/s`);
    const data = await res.json();
    setSettings(data);
  };

  useEffect(() => {
    getSettings();
  }, [setSettings]);

  document.title = 'Контакты | VR Surgut - Виртуальная реальность сейчас!'
    return (
      <>
      <section className="vr-main" style={{marginBottom: '100px'}}>
        <Container>
          <h4 className='vr-header_title'>
            Наши <span>контакты</span>
          </h4>
          <Row md={12} className='d-flex justify-content-center'>
            <Col className='vr-header_main p-4 h-100' md={6}>
              <p style={{textAlign: 'start'}} className='vr-howtogame_item_header'>Наименование: <span className="vr-howtogame_item_header text-warning">{settings.orgName}</span></p>
              <p style={{textAlign: 'start'}} className='vr-howtogame_item_header'>ИНН: <span className="vr-howtogame_item_header text-warning">{settings.innOrg}</span></p>
              <p style={{textAlign: 'start'}} className='vr-howtogame_item_header'>ОГРНИП: <span className="vr-howtogame_item_header text-warning">{settings.ogrnOrg}</span></p>
              <p style={{textAlign: 'start'}} className='vr-howtogame_item_header'>Телефон: <span className="vr-howtogame_item_header text-warning">{settings.orgPhone}</span></p>
              <p style={{textAlign: 'start'}} className='vr-howtogame_item_header'>Адрес: <span className="vr-howtogame_item_header text-warning">{settings.orgAddress}</span></p>
              <p style={{textAlign: 'start'}} className='vr-howtogame_item_header'>График: <span className="vr-howtogame_item_header text-warning">{settings.orgTimeJob}</span></p>
              <p style={{textAlign: 'start'}} className='vr-howtogame_item_header'>E-Mail: <span className="vr-howtogame_item_header text-warning">{settings.orgEmail}</span></p>
              <p style={{textAlign: 'start'}} className='vr-howtogame_item_header'>Сообщество: <span className="vr-howtogame_item_header text-warning">{settings.headerVkDecLink}</span></p>
            </Col>
            <Col className='vr-header_main rounded-3' md={6}>
              <div style={{position:'relative',overflow:'hidden'}}><a href="https://yandex.ru/maps/org/anvio/11399650623/?utm_medium=mapframe&utm_source=maps" style={{color:'#eee',fontSize:'12px',position:'absolute',top:'0px'}}>Анвио</a><a href="https://yandex.ru/maps/973/surgut/category/virtual_reality_club/191820096562/?utm_medium=mapframe&utm_source=maps" style={{color:'#eee',fontSize:'12px',position:'absolute',top:'14px'}}>Клуб виртуальной реальности в Сургуте</a><a href="https://yandex.ru/maps/973/surgut/category/quests/56396710895/?utm_medium=mapframe&utm_source=maps" style={{color:'#eee',fontSize:'12px',position:'absolute',top:'28px'}}>Квесты в Сургуте</a><iframe src="https://yandex.ru/map-widget/v1/?indoorLevel=5&ll=73.431843%2C61.256671&mode=search&oid=11399650623&ol=biz&z=17.8" width="100%" height="407px" frameBorder="1" allowFullScreen={true} style={{position:'relative'}}></iframe></div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row md={12} className='d-flex justify-content-between'>
            <Col className="vr-social" style={{background: `#393E41 url(${SocialImg}) no-repeat top`}} md={12}>
              <Row className='d-flex justify-content-between vr-social_content mx-4 ms-1'>
                <Col md={4}>
                  <h4 className='vr-header_title'>
                    VR Сургут <span>ВКонтакте</span>
                  </h4>
                  <h5 className='vr-games_card_title'>{settings.headerVkDecLink}</h5>
                  <p className='vr-header_text'>
                    {settings.headerVkText}
                  </p>
                  <a href={settings.headerVkLink} rel="noreferrer" target="_blank"><button type="button" className="btn btn-warning vr-btn">Подписаться на сообщество</button></a>
                </Col>
                <Col md={3}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="317" height="188" viewBox="0 0 317 188" fill="none">
                    <path d="M308.869 12.9662C311.074 5.64248 308.869 0.271729 298.443 0.271729H263.903C255.115 0.271729 251.098 4.91799 248.893 10.021C248.893 10.021 231.332 52.8452 206.447 80.6125C198.415 88.6922 194.745 91.2437 190.366 91.2437C188.177 91.2437 184.995 88.6922 184.995 81.3685V12.9662C184.995 4.17773 182.46 0.271729 175.136 0.271729H120.862C115.381 0.271729 112.073 4.33523 112.073 8.20973C112.073 16.5257 124.515 18.4472 125.791 41.8832V92.7242C125.791 103.859 123.791 105.891 119.381 105.891C107.679 105.891 79.2027 62.9095 62.303 13.7065C59.0112 4.14623 55.688 0.287485 46.868 0.287485H12.344C2.46875 0.287485 0.5 4.93373 0.5 10.0367C0.5 19.2032 12.2023 64.5632 55.0107 124.555C83.5498 165.521 123.728 187.728 160.331 187.728C182.271 187.728 184.98 182.798 184.98 174.293V143.329C184.98 133.469 187.074 131.485 194.02 131.485C199.123 131.485 207.912 134.068 228.402 153.803C251.807 177.207 255.682 187.712 268.833 187.712H303.357C313.216 187.712 318.146 182.783 315.311 173.049C312.208 163.363 301.026 149.267 286.189 132.587C278.125 123.074 266.076 112.837 262.407 107.718C257.288 101.119 258.768 98.2052 262.407 92.3462C262.422 92.362 304.491 33.1105 308.869 12.9662Z" fill="white" fillOpacity="0.25"/>
                  </svg>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
    );
};

export default observer(ContactsPage);