import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import HomePage from './pages/home-page';
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/style.css';

import Maket from './components/maket';
import GamePage from "./pages/game-page";
import NotFoundPage from "./pages/not-found-page";
import GamesPage from "./pages/games-page";
import MaketAlt from "./components/maket-alt";
import NewsPage from "./pages/news-page";
import NewsOnepage from "./pages/news-onepage";
import ContactsPage from "./pages/contacts-page";

function App() {
  return (
    <Router>
    <Routes>
      <Route element={<MaketAlt />}>
        <Route path="/games" element={<GamesPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="/games/:id" element={<GamePage />} />
        <Route path="/news/:id" element={<NewsOnepage />} />
      </Route>
      <Route element={<Maket />}>
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  </Router>
  )
}

export default App;