import React, { useState } from 'react';
import {observer} from "mobx-react-lite";
import 'boxicons';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import API_URL from '../http';

function Feedback(props) {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  function postTicket() {
    if(name === '') {
      return null;
    }
    if(phone === '') {
      return null;
    }
    if(message === '') {
      return null;
    }
    const formData = new FormData()
    formData.append('name', name)
    formData.append('phone', phone)
    formData.append('message', message)
    formData.append('ticketStatus', 1)
    axios.post(API_URL + '/t/create', formData)
    .then(res => {
      setName('');
      setPhone('');
      setMessage('');
      props.onHide();
    })
    .catch(err => null);
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-dark border border-dark text-white border-bottom-0" closeButton>
        <Modal.Title  id="contained-modal-title-vcenter">
          Обратная связь
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark p-5">
        <h5 class="vr-header_title_games">Имя:</h5>
        <InputGroup className="mb-3">
          <Form.Control
            style={{color: 'white', background: 'rgb(57, 62, 65)', border: '1px solid rgb(57, 62, 65)'}}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Имя"
            aria-label="Имя"
            aria-describedby="basic-addon1"
          />
        </InputGroup>
        <h5 class="vr-header_title_games">Телефон или E-Mail:</h5>
        <InputGroup className="mb-3">
        <Form.Control
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          style={{color: 'white', background: 'rgb(57, 62, 65)', border: '1px solid rgb(57, 62, 65)'}}
          placeholder="Телефон или E-Mail"
          aria-label="Телефон или E-Mail"
          aria-describedby="basic-addon1"
        />
        </InputGroup>
        <h5 class="vr-header_title_games">Содержимое обращения:</h5>
        <InputGroup className="mb-3">
        <Form.Control
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          style={{color: 'white', background: 'rgb(57, 62, 65)', border: '1px solid rgb(57, 62, 65)'}}
          as="textarea" rows={3}
          placeholder="Сообщение"
          aria-label="Сообщение"
          aria-describedby="basic-addon1"
        />
        </InputGroup>
        <button type="button" onClick={() => postTicket()} class="btn btn-warning vr-btn mt-2">Записаться онлайн</button>
      </Modal.Body>
    </Modal>
  );
}

const Header = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
        <Feedback
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <Navbar expand='xxl' variant="dark" className="bg-body-tertiary mb-3 vr-nav">
          <Container>
            <Link to="/" className="navbar-brand vr-brand">VR Сургут</Link>
            <Navbar.Toggle aria-controls='offcanvasNavbar-expand-xxl' />
            <Navbar.Offcanvas
              className="vr-navmobile"
              id='offcanvasNavbar-expand-xxl'
              aria-labelledby='offcanvasNavbarLabel-expand-xxl'
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id='offcanvasNavbarLabel-expand-xxl'>
                  VR Сургут
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-center flex-grow-1 pe-3">
                  <Link to="/" className='nav-link vr-nav_text'>Главная</Link>
                  <Link to="/games" className='nav-link vr-nav_text'>Игры</Link>
                  <Link to="/news" className='nav-link vr-nav_text'>Новости</Link>
                  <NavDropdown
                    className='vr-nav_text'
                    title="Информация"
                    id='offcanvasNavbarDropdown-expand-xxl'
                  >
                    <Link to="/pages/privacy" className='dropdown-item vr-nav_text'>
                      Политика конфиденциальности
                    </Link>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => setModalShow(true)} className='vr-nav_text'>
                      Обратная связь
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Link to="/contacts" className='nav-link vr-nav_text'>Контакты</Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </>
    );
  };

export default observer(Header);