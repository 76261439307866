import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {observer} from "mobx-react-lite";
import { Col, Container, Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player';
import NotFoundPage from './not-found-page';
import { Link } from 'react-router-dom';
import API_URL, {IMG_URL} from '../components/http';

function MyVerticallyCenteredModal(props) {
  const [trailer, setTrailer] = useState('');
  const {id} = useParams();
  const getOneGame = async () => {
    const res = await fetch(
      API_URL + `/g/${id}`
    );
    const data = await res.json();
    console.log(data.trailer);
    setTrailer(data.trailer);
  };
  useEffect(() => {
    getOneGame();
  },[setTrailer]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-dark border border-dark text-white border-bottom-0" closeButton>
        <Modal.Title  id="contained-modal-title-vcenter">
          Трейлер игры
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark">
      <ReactPlayer
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            onContextMenu={e => e.preventDefault()}
            url={IMG_URL + '/' +trailer}
            className="react-player"
            controls
            width="100%"
            height="100%"
        /> 
      </Modal.Body>
    </Modal>
  );
}


const GamePage = () => {

  const [modalShow, setModalShow] = React.useState(false);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const [items, setItems] = useState([]);
  const {id} = useParams();

  const getOneGame = async () => {
    const res = await fetch(
      API_URL + `/g/${id}`
    );
    const data = await res.json();
    setItems(data);
  };
  useEffect(() => {
    getOneGame();
  },[setItems]);

  if (items == null) return (
    <NotFoundPage />
  ) 

    return (
      <section className='vr-game'>
        <MyVerticallyCenteredModal
          show={modalShow}
          getMedia={id}
          onHide={() => setModalShow(false)}
        />
        <Container>
        <Link to="/games">
          <button type="button" style={{marginBottom: '20px', marginLeft: 0}} className="btn btn-dark vr-btn_alt"><i className='bx bx-arrow-back bx-fw' style={{paddingTop: '1px'}}></i> Вернуться назад</button>
        </Link>
          <Row md={12} className='d-flex justify-content-between'>
            <Col md={6}>
              <img className='vr-game_logotype' alt={items.title} src={IMG_URL + '/' + items.img} />
              <div className='vr-header_btn_group d-flex'>
                <a href="https://n942992.yclients.com/" rel="noreferrer" target="_blank"><button type="button" className="btn btn-warning vr-btn">Записаться</button></a>
                <button type="button" onClick={() => setModalShow(true)} className="btn btn-dark vr-btn_alt">Смотреть трейлер</button>
              </div>
            </Col>
            <Col md={6}>
              <h4 className='vr-header_title'>
                Игра <span>"{items.title}"</span>
              </h4>
              <h5 className='vr-game_cost'>
                  СТОИМОСТЬ ИГРЫ: {items.cost}
              </h5>
              <h5 style={{textTransform: 'uppercase'}} className='vr-game_cost'>
                  ЖАНР: <span>{items.typeGame}</span>
              </h5>
              <h5 className='vr-game_cost'>
                  КОЛИЧЕСТВО ИГРОКОВ: <span>{items.maxPlayers} ЧЕЛОВЕК</span>
              </h5>
              <h5 className='vr-game_cost'>
                  ВОЗРАСТНОЕ ОГРАНИЧЕНИЕ: <span>{items.age}+</span>
              </h5>
              <p style={{whiteSpace: 'pre-line'}} className='vr-header_text'>
                {items.description}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h5 style={{marginTop: '30px'}} className='vr-game_cost'>
                  ФОТО-МАТЕРИАЛЫ ИГРЫ:
              </h5>
              <Carousel style={{marginBottom: '20px'}} activeIndex={index} onSelect={handleSelect} className="vr-game_slider_box">
                {items.pic1 !== 'noimage.jpg' ?
                <Carousel.Item>
                  <img
                    className="d-block w-100 vr-game_slider"
                    src={IMG_URL + '/' + items.pic1}
                    alt="First slide"
                  />
                </Carousel.Item>
                : null
                }
                {items.pic2 !== 'noimage.jpg' ?
                <Carousel.Item>
                  <img
                    className="d-block w-100 vr-game_slider"
                    src={IMG_URL + '/' + items.pic2}
                    alt="First slide"
                  />
                </Carousel.Item>
                : null
                }
                {items.pic3 !== 'noimage.jpg' ?
                <Carousel.Item>
                  <img
                    className="d-block w-100 vr-game_slider"
                    src={IMG_URL + '/' + items.pic3}
                    alt="First slide"
                  />
                </Carousel.Item>
                : null
                }
                {items.pic4 !== 'noimage.jpg' ?
                <Carousel.Item>
                  <img
                    className="d-block w-100 vr-game_slider"
                    src={IMG_URL + '/' + items.pic4}
                    alt="First slide"
                  />
                </Carousel.Item>
                : null
                }
                {items.pic5 !== 'noimage.jpg' ?
                <Carousel.Item>
                  <img
                    className="d-block w-100 vr-game_slider"
                    src={IMG_URL + '/' + items.pic5}
                    alt="First slide"
                  />
                </Carousel.Item>
                : null
                }
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
    );
};

export default observer(GamePage);