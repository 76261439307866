import React from 'react';
import {observer} from "mobx-react-lite";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';



const NotFoundPage = () => {
  document.title = 'Страница не найдена! | VR Surgut - Виртуальная реальность сейчас!';
    return (
      <section className="vr-main">
      <Container>
        <Row md={12} className='d-flex justify-content-between'>
          <Col className='vr-header_main' md={4}>
            <h4 className='vr-header_title'>
              Ой, данная страница <span>не найдена!</span>
            </h4>
            <p className='vr-header_text'>
              Страница, которую вы пытаетесь открыть не найдена! Если вы считаете, что данная страница есть и это возникло по ошибке - сообщите нам!
            </p>
            <svg xmlns="http://www.w3.org/2000/svg" width="170" height="15" viewBox="0 0 170 15" fill="none">
              <path d="M1 7.5C6.6 -1.16667 12.2 -1.16667 17.8 7.5C23.4 16.1667 29 16.1667 34.6 7.5C40.2 -1.16667 45.8 -1.16667 51.4 7.5C57 16.1667 62.6 16.1667 68.2 7.5C73.8 -1.16667 79.4 -1.16667 85 7.5C90.6 16.1667 96.2 16.1667 101.8 7.5C107.4 -1.16667 113 -1.16667 118.6 7.5C124.2 16.1667 129.8 16.1667 135.4 7.5C141 -1.16667 146.6 -1.16667 152.2 7.5C157.8 16.1667 163.4 16.1667 169 7.5" stroke="#FFBF46" strokeOpacity="0.7" strokeWidth="2"/>
            </svg>
            <div className='vr-header_btn_group d-flex'>
              <Link to="/"><button type="button" className="btn btn-warning vr-btn">На главную</button></Link>
            </div>
          </Col>
          <Col md={4}>
            <svg className='vr-notfound' width="346" height="346" viewBox="0 0 346 346" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M288.333 28.8333H57.6666C41.7651 28.8333 28.8333 41.7651 28.8333 57.6667V230.667C28.8333 246.568 41.7651 259.5 57.6666 259.5H100.917V313.808L191.41 259.5H288.333C304.235 259.5 317.167 246.568 317.167 230.667V57.6667C317.167 41.7651 304.235 28.8333 288.333 28.8333ZM288.333 230.667H183.423L129.75 262.859V230.667H57.6666V57.6667H288.333V230.667Z" fill="#FFBF46" fillOpacity="0.7"/>
              <path d="M158.583 86.5H187.417V158.583H158.583V86.5ZM158.583 173H187.417V201.833H158.583V173Z" fill="#FFBF46" fillOpacity="0.7"/>
            </svg>
          </Col>
        </Row>
      </Container>
    </section>
    );
};

export default observer(NotFoundPage);